<template lang="pug">
    div.main-container(id="container")
        div.overlay
        div.search-overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 Dashboard del administrador de contenidos
                    
                    div
                        nav.breadcrumb-one(aria-label="breadcrumb")
                            ol.breadcrumb
                                li.breadcrumb-item
                                    a(href="javascript:void(0);") Dashboard
                                li.breadcrumb-item.active(aria-current="page") Mi Dashboard

                div.row.mt-5
                    div.infobox-3.col-md-3.widget.bg-white
                        div.info-icon
                            svg.feather.feather-cpu(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                rect(x="4" y="4" width="16" height="16" rx="2" ry="2")
                                rect(x="9" y="9" width="6" height="6")
                                line(x1="9" y1="1" x2="9" y2="4")
                                line(x1="15" y1="1" x2="15" y2="4")
                                line(x1="9" y1="20" x2="9" y2="23")
                                line(x1="15" y1="20" x2="15" y2="23")
                                line(x1="20" y1="9" x2="23" y2="9")
                                line(x1="20" y1="14" x2="23" y2="14")
                                line(x1="1" y1="9" x2="4" y2="9")
                                line(x1="1" y1="14" x2="4" y2="14")
                        h5.info-heading Crear Examenes
                        p.info-text Ingrese un examen nuevo
                        router-link.info-link(to="/content/CreateExam") Ver
                            svg.feather.feather-arrow-right(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                line(x1="5" y1="12" x2="19" y2="12")
                                polyline(points="12 5 19 12 12 19")
                                  
                                    
                    div.infobox-3.col-md-3.widget.bg-white
                        div.info-icon
                            svg.feather.feather-clipboard(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                path(d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2")
                                rect(x="8" y="2" width="8" height="4" rx="1" ry="1")
                        h5.info-heading Examenes
                        p.info-text Consulte y revise sus examenes
                        router-link.info-link(to="/content/exams") Ver 
                            svg.feather.feather-arrow-right(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                line(x1="5" y1="12" x2="19" y2="12") 
                                polyline(points="12 5 19 12 12 19")
                                    
                                    

                div.footer-wrapper
                    div.footer-section.f-section-1
                        p © 2021 
                            a(target="_blank" href="https://etciberoamerica.com/") ETC Iberoamérica
                            template , Todos los derechos reservados.
                    div.footer-section.f-section-2
                        p

</template>

<script>
/* eslint-disable */
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {
        //this.checkPermisions(this.$router.currentRoute.path)
    }
}
</script>
